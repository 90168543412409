import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const ThreatIntelligence = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="ThreatIntelligence"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">The traditional approach to enterprise security involves sharing of threat data and manual coordination between teams relying on email, spreadsheets, ticketing systems, etc. </p>
                        <p className="mt-3">This approach results in a slow and reactionary response to the security incidents as the analysts lack relevant information. 
With security threats and breach methods evolving rapidly, security intelligence needs to be built-in by design, security data & insights need to be collected & analyzed in real-time and automated to accelerate breach detection in IT systems and functions.</p>
                        <p className="mt-2">TEQT iDART Threat Intelligence services help in the Intelligent Detection & Response of Threats. The iDART Threat Intelligence platform entails evidence-based knowledge for the search of indicators of compromise (IOCs) including context, mechanisms, implications, and action-oriented advice about an existing or emerging IT threat to customer environments. </p>
                        <p className="mt-2">iDART provides actionable threat intelligence to our analysts to enable them in implementing measures to proactively protect the customer IT environment.</p>

                        <h6>The features of iDART platform include:</h6>

                        <ul className="circle-list">
                            <li className="mt-2">Automated consumption of internal & external threat intelligence and refine as necessary</li>
                            <li className="mt-2">Monitor and Investigate the Dark Web</li>
                            <li className="mt-2">Behavioral profiling for users & systems</li>
                            <li className="mt-2">DB searches and statistical modelling</li>
                            <li className="mt-2">Search for unknown potential threats</li>
                            <li className="mt-2">Analyze threats and adversaries</li>
                        </ul>
                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query ThreatIntelligencePageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

ThreatIntelligence.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default ThreatIntelligence;
